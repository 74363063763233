<template>
  <div>
    <div class="table-responsive">
      <table class="table mb-0">
        <thead>
        <tr>
          <th
              class="table--th-view p-0"
              width="10px"
          ></th>
          <th class="table--th-view">
            <span class="d-flex align-items-center">
                <div
                    @click="sort(sortName.prop)"
                    class="d-flex"
                    style="cursor: pointer"
                >
                    <span>{{ $t('project.name') }}</span>
                    <div class="d-flex flex-column table-sort-icons">
                        <span v-if="'name' == sortName.prop">
                            <font-awesome-icon
                                :class="sortName.order == 'asc' ? 'active' : ''"
                                class="caret-sort caret-sort-up"
                                icon="caret-up"
                            />
                            <font-awesome-icon
                                :class="sortName.order == 'desc' ? 'active' : ''"
                                class="caret-sort caret-sort-down"
                                icon="caret-down"
                            />
                        </span>
                        <span v-else>
                            <font-awesome-icon
                                class="caret-sort caret-sort-up"
                                icon="caret-up"
                            />
                            <font-awesome-icon
                                class="caret-sort caret-sort-down"
                                icon="caret-down"
                            />
                        </span>
                    </div>
                </div>
            </span>
          </th>
          <th
              :key="item"
              :style="item === 'projects.team' ? 'width: 200px' : ''"
              class="table--th-view"
              v-for="item in thead"
          >
              <span class="d-flex align-items-center">
                  {{ $t(item) }}
              </span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            :key="key"
            :style="showRow(row)"
            v-for="(row, key) in formatData"
        >
          <td
              class="table--tbody"
              width="10px"
          >
            <div style="display: contents">
              <div
                  :data-status="getStatusColor(row['due_date'])"
                  :id="'statusColor_' + row.id"
                  :ref="'statusColor_' + row.id"
                  style="width: 10px; height: 100%; display: table;"
              ></div>
            </div>
          </td>
          <td
              class="table--tbody"
              style="min-width: 534px"
          >
            <div
                class="d-flex align-items-center justify-content-between"
                style="height: 100%;"
            >
              <div
                  class="d-flex justify-content-start align-items-center"
                  style="min-width: 500px; text-align: left; white-space: normal"
              >
                <span
                    :key="space"
                    class="ms-tree-space"
                    v-for="space in row._level"
                ></span>
                <span
                    @click="toggleExpanded(key)"
                    class="tree-ctrl"
                    v-if="iconShow(0, row)"
                >
                                <i
                                    class="el-icon-plus"
                                    v-if="!row._expanded"
                                ></i>
                                <i
                                    class="el-icon-minus"
                                    v-else
                                ></i>
                            </span>
                <router-link :to="'/project/' + row.id">
                  {{ row.name }}
                </router-link>
              </div>
              <!--                        <el-dropdown trigger="click" class="p-0">-->
              <!--                            <div class="cursor-pointer" @click="setProjectId(row.id)">-->
              <!--                                <font-awesome-icon icon="ellipsis-v"/>-->
              <!--                            </div>-->
              <!--                          <el-dropdown-menu slot="dropdown">-->
              <!--                              <el-dropdown-item @click.native="redirectToEdit">{{$t('system.edit')}}</el-dropdown-item>-->
              <!--                              <el-dropdown-item @click.native="deleteProject" style="color: red;">{{$t('system.delete')}}</el-dropdown-item>-->
              <!--                          </el-dropdown-menu>-->
              <!--                        </el-dropdown>-->

            </div>
          </td>
          <td
              :key="item"
              class="table--tbody"
              v-for="item in thead"
          >
            <div
                style="position: inherit; text-align:left;"
                v-if="item == 'projects.team'"
            >
              <ul class="projectList--team">
                <li
                    :key="user.id"
                    class="mr-1"
                    v-for="user in currentNumberUsers(row['team'], 5)"
                >
                  <el-tooltip
                      :key="getAvatarById(user.id)"
                      effect="dark"
                      placement="bottom"
                  >
                    <div slot="content">{{ user.name }}<br />{{ getTooltipContent(user.roles) }}</div>
                    <img
                        :alt="user.name"
                        :src="getAvatarById(user.id)"
                        class="avatar--ifImage"
                        v-if="getAvatarById(user.id)"
                    >
                    <span
                        class="avatar--ifNotImage"
                        v-else
                    >
                          {{ abbreviation(user.name) }}
                      </span>
                  </el-tooltip>
                </li>
                <li v-if="row['team'] != null && Object.keys(row['team']).length > 5">
                  <div
                      class="p-0"
                      style="white-space: normal; position: inherit"
                  >
                    <el-popover
                        :append-to-body="false"
                        :popper-options="{
                                    boundariesElement: 'body',
                                    gpuAcceleration: true,
                                    positionFixed: false
                                  }"
                        placement="top-end"
                        popper-class="projectList--popover"
                        trigger="click"
                    >
                            <span class="projectList--popoverTeam">
                                <span
                                    :key="user.id"
                                    style="display: inline-block; padding: 0.15rem"
                                    v-for="user in row['team']"
                                >
                                   <el-tooltip
                                       :key="getAvatarById(user.id)"
                                       effect="dark"
                                       placement="bottom"
                                   >
                                    <div slot="content">{{ user.name }}<br />{{ getTooltipContent(user.roles) }} </div>
                                    <img
                                        :alt="user.name"
                                        :src="getAvatarById(user.id)"
                                        class="avatar--ifImage"
                                        v-if="getAvatarById(user.id)"
                                    >
                                    <span
                                        class="avatar--ifNotImage"
                                        v-else
                                    >
                                        {{ abbreviation(user.name) }}
                                    </span>
                                  </el-tooltip>
                                </span>
                            </span>
                      <el-button
                          circle
                          slot="reference"
                      >
                        <span v-if="Object.keys(row['team']).length <= 99">+{{
                            Object.keys(row['team']).length - 5
                                                                           }}</span>
                        <span v-else>>99</span>
                      </el-button>
                    </el-popover>
                  </div>
                </li>
              </ul>
            </div>
            <div
                class="pl-2 pr-2"
                v-else-if="item == 'projects.start_date' && row['start_date']"
            >
                <span v-if="moment(row['start_date'])">
                    {{ moment(row['start_date']) }}
                </span>
              <span v-else></span>
            </div>
            <div
                class="pl-2 pr-2"
                v-else-if="item == 'projects.due_date' && row['due_date']"
            >
                <span v-if="moment(row['due_date'])">
                    {{ moment(row['due_date']) }}
                    <span
                        :data-status="getStatusColor(row['due_date'])"
                        :ref="'statusColor_' + row.id + '_' + row['status_id']"
                        class="bg-transparent pl-2"
                        style="font-weight: 600; background:transparent; !important;"
                    >
                    <span v-if="moment() < moment(row['due_date'])">
                      <span v-if="days_left(row['due_date']) < 3">
                         ({{ days_left(row['due_date']) }} {{ $t('projects.days_left') }})
                      </span>
                      <span v-else>
                         ({{ days_left(row['due_date']) }} {{ $t('projects.days_left') }})
                      </span>
                    </span>
                    <span v-else-if="moment() === moment(row['due_date'])"></span>
                    <span v-else>
                       ({{ days_left(row['due_date']) | negativeDate }} {{ $t('projects.days_passed') }})
                    </span>
                  </span>
                </span>
              <span v-else></span>
            </div>
            <div
                class="p-0"
                style="height: 100%;"
                v-else-if="item == 'projects.status_id'"
            >
              <div
                  :style="[row['status'].color ? {'background-color': row['status'].color} : {'background-color':'#d9dfe8'}]"
                  class="projectList--statusColor p-0 d-flex align-items-center justify-content-center"
                  style="width: 100%; height: 100%;"
              >
                {{ row['status'].name }}
              </div>
            </div>
            <div v-else>
              {{ row[item] ? row[item] : $t('system.no_data') }}
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-if="formatData.length == 0">
      <span class="no-date-title">{{ $t('system.no_data') }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import treeToArray from './eval';
import {abbreviation} from '../../helpers/functions';

export default {
  name: 'treeTable',
  filters: {
    negativeDate: function (date) {
      if (typeof date !== 'undefined' && date != null) {
        return Math.abs(date);
      }
      return null;
    },
  },
  data() {
    return {
      // evalArgs: Array,
      refKeys: [],
      route: 'Overview',
      expandAll: false,
      sortName: {
        prop: 'name',
        order: 'desc',
      },
      collapsed: {},
      project_id: null,
      tags: [],
    };
  },
  updated() {
    this.$nextTick(function () {
      this.setRefKeys();
    });
  },
  beforeDestroy() {
    this.refKeys = [];
    this.tags = [];
    this.collapsed = {};
  },
  props: {
    data: {
      type: [Array, Object],
      required: true,
    },
    usersAvatars: {
      type: [Array],
    },
    evalFunc: Function,
    evalArgs: Array,
    thead: {
      type: [Array, Object],
      required: true,
    },
  },
  computed: {
    formatData: function () {
      let tmp;
      if (!Array.isArray(this.data)) {
        tmp = [this.data];
      } else {
        tmp = this.data;
      }
      const func = this.evalFunc || treeToArray;
      const args = this.evalArgs ? Array.concat([tmp, this.expandAll], this.evalArgs) : [tmp, this.expandAll];
      return func.apply(null, args);
    },
  },
  methods: {
    getAvatarById(id) {
      const candidate = this.usersAvatars.find(avatar => avatar.id === id);
      return candidate?.img;
    },
    abbreviation(str) {
      return abbreviation(str);
    },
    currentNumberUsers(obj, size) {
      let result = {};
      let i = 0;
      for (let item in obj) {
        if (size > i) {
          if (item) {
            result[i] = obj[item];
          }
        }
        i++;
      }
      return result;
    },
    showRow: function (row) {
      /* eslint-disable */
      const show = (row.parent ? (row.parent._expanded && row.parent._show) : true);
      row._show = show;
      return show ? 'animation:treeTableShow 1s;-webkit-animation:treeTableShow 1s;' : 'display:none;';
    },
    toggleExpanded: function (trIndex) {
      /* eslint-disable */
      const record = this.formatData[trIndex];
      record._expanded = !record._expanded;
    },
    iconShow(index, record) {
      return (index === 0 && record.children && record.children.length > 0);
    },
    moment: function (date) {
      if (date !== '0000-00-00 00:00:00') {
        return moment(date).format('YYYY-MM-DD');
      } else {
        return '';
      }
    },
    //TODO: dopasować do danych z back-endu
    setRefKeys() {
      this.refKeys = [];
      for (let k in this.$refs) {
        if (k.startsWith('statusColor')) {
          // let splitedStr = k.split('_');
          //   let status = this.formatDataKeyById.splitedStr[1].status;
          let $ref = this.$refs[k];
          if (this.$refs[k] && this.$refs[k].parentElement) {
            $ref = this.$refs[k].parentElement.parentElement;
            let status = this.$refs[k].getAttribute('data-status');
            if (status === 'risk') {
              $ref.style.background = 'orange';
            }
            if (status === 'exceeded') {
              $ref.style.background = 'red';
            }
            if (status === 'normal') {
              $ref.style.background = 'green';
            }
          } else if (this.$refs[k] && this.$refs[k][0]) {
            $ref = this.$refs[k][0];
            let status = this.$refs[k][0].getAttribute('data-status');
            if (status === 'risk') {
              $ref.style.background = 'orange';
              $ref.style.color = 'orange';
            }
            if (status === 'exceeded') {
              $ref.style.background = 'red';
              $ref.style.color = 'red';
            }
            if (status === 'normal') {
              $ref.style.background = 'green';
              $ref.style.color = 'green';
            }
          }
        }
      }
    },
    getStatusColor(date) {
      let now = moment().format('YYYY-MM-DD');
      let dueDate = moment(date).format('YYYY-MM-DD');
      var diff = this.days_left(date);
      if (now > dueDate) {
        return 'exceeded';
      }
      if (now < dueDate) {
        if (diff < 3) return 'risk';
        return 'normal';
      }
      return '';
    },
    days_left: function (date) {
      return moment(moment(date).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'days');
    },
    getTooltipContent(roles) {
      let tmp = [];
      Object.keys(roles).forEach((key) => {
        tmp.push(roles[key].name);
      });
      return '(' + tmp.toString() + ')';
    },
    sort(column) {
      if (column === this.sortName.prop) {
        if (this.sortName.order === 'desc') {
          this.sortName.order = 'asc';
        } else {
          this.sortName.order = 'desc';
        }
      } else {
        this.sortName.prop = column;
        this.sortName.order = 'asc';
      }
      this.$emit('sort', this.sortName);
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
@import '../../static/css/table.scss';

$color-blue: #2196F3;
$space-width: 18px;
.ms-tree-space {
  position: relative;
  top: 1px;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  width: $space-width;
  height: 14px;
  min-width: 18px;
  max-width: 18px;

  &::before {
    content: ''
  }
}

.tree-ctrl {
  position: relative;
  cursor: pointer;
  color: $color-blue;
  width: 18px;
  margin-left: -$space-width;
}

.projectList--team {
  margin: 0;
  padding: 0;

  li {
    display: inline-block;

    .el-button {
      height: 25px;
      width: 25px;
      padding: 0;
    }
  }
}

.projectList--popoverTeam {
  white-space: normal;
  max-height: 100px;
  overflow-y: scroll;
  display: block;
  background-color: rgba(0, 0, 0, 0);
  -webkit-background-clip: text;
  transition: background-color .3s;

  &::-webkit-scrollbar {
    width: 0.30em !important;
    height: 3px;

  }

  &::-webkit-scrollbar-track {
    border-radius: 1.5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1.5px;
    background-color: inherit;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.18);
  }
}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.projectList--popover {
  padding: 10px 0px 10px 10px !important;
  max-width: 290px;
  max-height: 140px;
}
</style>
