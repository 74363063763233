<template>
  <div class="view-container">
    <div class="view">
      <div class="view-body">
        <div class="row">
          <div class="col-3">
            <router-link
                :to="create"
                v-if="ifHasPermission(['project-create'])"
            >
              <el-button
                  :size="fieldsSize"
                  type="primary"
              >
                <font-awesome-icon
                    class="mr-1"
                    icon="plus"
                />
                {{ $t('system.project') }}
              </el-button>
            </router-link>
          </div>
          <div class="col-9">
            <div class="d-flex justify-content-end select-filter">
              <el-select
                  :placeholder="$t('system.members')"
                  :size="fieldsSize"
                  @change="addUserTag"
                  collapse-tags
                  filterable
                  multiple
                  v-model="filter.user"
              >
                <el-option
                    :key="user.id"
                    :label="user.name"
                    :value="user.id"
                    v-for="user in users"
                >
                </el-option>
              </el-select>
              <DebounceInput
                  :placeholder="$t('system.search_by_name')"
                  :size="fieldsSize"
                  @change="fetchData"
                  class="ml-3"
                  clearable
                  style="max-width: 220px"
                  v-model="filter.name"
              />
            </div>
          </div>
          <div
              class="col-12"
              v-if="tags.length > 0"
          >
            <div class="pt-4">
              <el-tag
                  :key="tag.name + tag.type + tag.id"
                  :size="fieldsSize"
                  :type="tag.color"
                  @close="handleClose(tag)"
                  class="d-inline-flex align-items-center mr-2 ml-0"
                  closable
                  v-for="tag in tags"
              >
                <span style="max-width: 400px; overflow: hidden; text-overflow: ellipsis; display: inline-block;">{{
                    tag.name
                                                                                                                  }}</span>
              </el-tag>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="view brd-none">
      <div class="view-body">
        <project-tree
            :data="model"
            :thead="selectThead"
            :usersAvatars="usersAvatars"
            @refreshProjectList="fetchData"
            @sort="sort"
            v-loading="loading"
        />
      </div>
    </div>
    <div class="view brd-none">
      <div class="view-body pt-0">
        <el-card
            class="pagination-container mt-0"
            shadow="never"
        >
          <el-pagination
              :current-page="params.page"
              :page-size="params.per_page"
              :page-sizes="[20, 30, 50, 300]"
              :total="total"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import projectTree from '../../components/projectsTree/projectTree.vue';
import notification from '../../notification/notify';
import DebounceInput from '../../components/DebounceInput';
import {distinct} from '@/helpers/arrayFunctions';

export default {
  name: 'ProjectIndex',
  data() {
    return {
      title: 'Projects',
      create: '/project/create',
      model: [],
      prevUsers: [],
      users: [],
      loading: true,
      filter: {
        user: [],
        name: '',
      },
      usersAvatars: [],
      showFilters: false,
      params: {
        column: 'name',
        direction: 'asc',
        per_page: 50,
        page: 1,
      },
      total: 0,
      tags: [],
      fieldsSize: 'small',
    };
  },
  components: {
    DebounceInput,
    'project-tree': projectTree,
    // AbDropdown
  },
  beforeMount() {
    this.getFilters();
    this.fetchData();
  },
  beforeDestroy() {
    this.model = [];
    this.prevUsers = [];
    this.filter = {};
    this.tags = [];
  },
  computed: {
    selectThead() {
      return this.$store.getters.systemSetting['selectProjectThead'] ? this.$store.getters.systemSetting['selectProjectThead'] : [];
    },
  },
  methods: {
    handleClose(tag) {
      // deleleting ftom select
      let indexOfSelect = this.filter[tag.type].indexOf(tag.id);
      this.filter[tag.type].splice(indexOfSelect, 1);
      // seleting from tags
      let indexOfTag = this.tags.indexOf(tag);
      this.tags.splice(indexOfTag, 1);
      this.fetchData();
    },
    addUserTag(data) {
      if (this.prevUsers.length > data.length) {
        let id = this.prevUsers.filter(item => !data.includes(item))[0]; // [88,89] -> [89] => 88 (id)
        let index = null;
        for (let tagIdx in this.tags) {
          let tag = this.tags[tagIdx];
          if (tag.id === id && tag.type === 'user') {
            index = tagIdx;
          }
        }
        if (index) {
          this.tags.splice(index, 1);
        }
      } else {
        let id = data[data.length - 1];
        this.tags.push({
          id: id,
          name: this.users.find(item => item.id === id).name,
          type: 'user',
          color: '',
        });
      }
      this.prevUsers = data;
      this.fetchData();
    },
    getUsersIds(projects) {
      const usersIdsByProject = projects.reduce((list, project) => {
        let ids = [...list, Object.keys(project.team).map(key => project.team[key].id)];
        if (project.children?.length) {
          ids = [...ids, ...this.getUsersIds(project.children)];
        }
        return ids;
      }, []);
      return distinct(usersIdsByProject.flat(1));
    },
    async fetchAvatars(avatarsIds) {
      return Promise.all(avatarsIds.map((avatarId, index) =>
          axios.get(`avatar/${avatarId}`, {
            responseType: 'blob',
            params: {type: 'mime_type'},
          }).then(avatarResponse => {
            if (avatarResponse.status === 200 && avatarResponse.data?.size) {
              const reader = new FileReader();
              reader.readAsDataURL(avatarResponse.data);
              reader.onload = () => {
                const candidateIdx = this.usersAvatars.findIndex(item => item.id === avatarsIds[index]);
                if (candidateIdx !== -1) {
                  this.usersAvatars[candidateIdx].img = reader.result;
                } else {
                  this.usersAvatars.push({
                    id: avatarsIds[index], img: reader.result,
                  });
                }
              };
            } else {
              this.usersAvatars.push({id: avatarsIds[index]});
            }
          }),
      ));
    },
    async fetchData() {
      this.loading = true;
      const p = this.params;
      await axios.get(`project?column=${p.column}&direction=${p.direction}&per_page=${p.per_page}&page=${p.page}`, {
        params: this.filter,
      })
          .then((response) => {
            this.model = response.data.data;
            this.total = response.data.total;
            this.loading = false;
            if (this.selectThead.includes('projects.team')) {
              const usersIds = this.getUsersIds(this.model);
              const idsToFetch = usersIds.filter(x => !this.usersAvatars.map(item => item.id).includes(x));
              const idsToRemove = this.usersAvatars.map(item => item.id).filter(x => !usersIds.includes(x));

              // fetch avatars
              this.fetchAvatars(idsToFetch);

              // remove unused avatars
              this.usersAvatars = this.usersAvatars.filter(item => !idsToRemove.includes(item.id));
            }

          })
          .catch((error) => {
            console.log('error', error);
            this.loading = false;
            notification.notify(
                this.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    getFilters() {
      axios.get(`project_filters`)
          .then((response) => {
            this.users = response.data.users;
          })
          .catch((error) => {
            notification.notify(
                this.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.fetchData();
    },
    handleSizeChange(val) {
      this.params.per_page = val;
      this.fetchData();
    },
    next() {
      this.params.page++;
      this.fetchData();
    },
    prev() {
      this.params.page--;
      this.fetchData();
    },
    sort(column) {
      if (typeof column.prop !== 'undefined' && column.prop !== null) {
        let order = column.order;
        if (typeof column.order !== 'undefined') {
          switch (column.order) {
            case 'desc': {
              order = 'asc';
              break;
            }
            case 'asc': {
              order = 'desc';
              break;
            }
          }
          this.params.column = column.prop;
          this.params.direction = order;
          this.fetchData();
        }
      }
    },
  },
};

</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.el-select-dropdown.el-popper {
  min-width: 250px !important;
}

.select-filter {
  .el-select {
    span:first-child {
      display: contents;
    }

    .el-tag:first-child {
      display: flex;
      align-items: center;

      .el-select__tags-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        max-width: 100px;
      }
    }
  }
}
</style>
